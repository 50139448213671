import React, { useState } from 'react';
import { useCartsQueries } from '../../api/hooks/vendor/carts/use-carts-queries';
import QuerySuspense from '../../api/query-suspense';
import { Cart as CartInterface } from '../../api/types/vendor/carts-types';
import { CartList } from '../../components/cart-list';
import {
  ModalCartAction,
  ModalCartActionModes,
} from '../../components/modals/modal-cart-action';
import useCartsQueryObserver from '../../hooks/use-carts-query-observer';
import useRefetchOnAppFocus from '../../hooks/use-refetch-on-app-focus';
import useNewOrdersChannel from '../../websocket/hooks/use-new-orders-channel';
import useOpeningStateChannel from '../../websocket/hooks/use-opening-state-channel';
import useWakeSleepChannel from '../../websocket/hooks/use-wake-sleep-channel';
import { twCb, useT } from '../../utils/helper';
import { useTerminalSetting } from '../../contexs/terminal-setting-context';
import { View, useWindowDimensions } from 'react-native';
import { getContainerClass } from '../../utils/conditional-style';
import { tw } from '../../utils/tailwind';
import SplashScreen from '../splash/splash.screen';
import { useQueryClient } from '@tanstack/react-query';
import { OnboardingPopup } from '../../components/web/onboarding-popup';
import { AppStackScreenProps } from '../../routes';
import { FooterBar } from '../../components/cash-register/footer-bar/footer-bar';
import { FooterBarItem } from '../../components/cash-register/footer-bar/footer-bar-item';
import { useTenant } from '../../contexs/tenant-context';

interface VendorDashboardScreenProps {
  navigation: AppStackScreenProps<'VendorDashboard'>['navigation'];
}

export const VendorDashboardScreen = (props: VendorDashboardScreenProps) => {
  // Standard hooks
  const t = useT();
  const queryClient = useQueryClient();
  // Custom hooks
  const { tenant } = useTenant();
  // States & Queries
  const { fetchCarts } = useCartsQueries();
  const { store_app_type } = useTerminalSetting();
  const [currentCart, setCurrentCart] = useState<CartInterface>();
  const { width } = useWindowDimensions();
  const [modalCartActionOptionsMode, setModalCartActionMode] =
    useState<ModalCartActionModes>('off');
  const [isOnboarding, setIsOnboarding] = useState(
    // Show onboarding only if user is not navigating to this screen
    !props.navigation.canGoBack(),
  );

  const isNative = store_app_type === 'native';

  // Connect to a websocket and listen for new orders
  useNewOrdersChannel();
  useRefetchOnAppFocus();
  useCartsQueryObserver();
  useOpeningStateChannel();
  // React native
  useWakeSleepChannel();

  return (
    <View style={tw`${getContainerClass(width, 24)} flex-1`}>
      {/* Show onboarding popup */}
      {isOnboarding && store_app_type !== 'native' && (
        <OnboardingPopup
          onConfirm={() => {
            setIsOnboarding(false);
          }}
        />
      )}

      <QuerySuspense
        query={fetchCarts}
        onSuccess={data => (
          <CartList
            carts={data}
            refreshing={fetchCarts.isRefetching}
            onRefresh={() => {
              fetchCarts.refetch();
              queryClient.invalidateQueries({
                queryKey: ['tenantsDashboard'],
              });
            }}
            onShowModalCartAction={({ mode, cart }) => {
              setCurrentCart(cart);
              setModalCartActionMode(mode);
            }}
            classNames={twCb(isNative ? 'p-4' : 'md:p-3 p-2')}
          />
        )}
        loadingComponent={<SplashScreen />}
      />

      {/* Back to cash register button */}
      {tenant.plan?.cash_register_enabled ? (
        <View style={tw`self-start left-0 bottom-0 absolute w-full`}>
          <FooterBar>
            <FooterBarItem
              text={t('backToRestaurant')}
              onPress={() => {
                props.navigation.navigate('Root');
              }}
              active={false}
            />
          </FooterBar>
        </View>
      ) : null}

      {/* Show list of cancelation, estimation and delay options */}
      {currentCart ? (
        <ModalCartAction
          mode={modalCartActionOptionsMode}
          cart={currentCart}
          onModalHide={() => {
            setModalCartActionMode('off');
          }}
        />
      ) : null}
    </View>
  );
};
