import React, { memo, useCallback } from 'react';
import { View } from 'react-native';
import { tw } from '../utils/tailwind';
import { Cart as CartInterface } from '../api/types/vendor/carts-types';
import { CartComment } from './carts/cart-comment';
import { CartHeader } from './carts/cart-header';
import { CartOrderInfo } from './carts/cart-order-info';
import { CartSummary } from './carts/cart-summary';
import { CartDetail } from './carts/cart-detail';
import { LineItem } from './carts/line-item';
import { CartActionButtons } from './carts/cart-action-buttons';
import { ModalCartActionModes } from './modals/modal-cart-action';
import { useT } from '../utils/helper';

export type ShowModalCartActionData = {
  cart: CartInterface;
  mode: ModalCartActionModes;
};

interface CartProps {
  cart: CartInterface;
  onShowModalCartAction?: (data: ShowModalCartActionData) => void;
}

export const Cart = (props: CartProps) => {
  const t = useT();
  const onShowModalCartAction = useCallback(
    (mode: ModalCartActionModes) => {
      if (props.onShowModalCartAction) {
        props.onShowModalCartAction({ mode, cart: props.cart });
      }
    },
    [props.onShowModalCartAction, props.cart],
  );

  return (
    <View style={tw`flex shadow-lg rounded-lg bg-white`}>
      {/* Header */}
      <CartHeader
        orderNumber={props.cart.external_or_internal_order_number}
        formattedOrderedAt={props.cart.formatted_ordered_at}
        cartType={props.cart.cart_type}
        cartTypeTranslated={props.cart.cart_type_translated}
      />

      {/* Body */}
      <View
        style={tw`md:flex-row md:gap-x-0 md:pb-2.5 md:border-b-2 gap-x-5 items-start mb-2.5 border-0 border-b-2 border-solid border-gray-100 pb-3 border-0`}>
        {/* Cart Items */}
        <View
          style={tw`md:w-1/2 md:border-r-2 md:pr-2.5 w-full border-solid border-gray-100`}>
          <View
            style={tw`md:pb-5 md:border-b-2 gap-y-4 mb-2.5 pt-3 px-3 pb-3 border-0 border-solid border-gray-100`}>
            {props.cart.line_items.map((item, index) => (
              <LineItem key={index} item={item} />
            ))}

            <CartDetail
              difference_to_min_value={props.cart.difference_to_min_value}
              delivery_charge={props.cart.delivery_charge}
              discount={props.cart.discount}
              vouchers={props.cart.vouchers}
              tip_amount={props.cart.tip_amount}
            />
          </View>

          <CartSummary total={props.cart.total} />
        </View>

        {/* Comment, Notes and Order Info */}
        <View style={tw`md:w-1/2 md:pl-2.5 w-full `}>
          {props.cart.comment ? (
            <CartComment title={t('comment')} content={props.cart.comment} />
          ) : null}
          <View
            style={tw`flex flex-wrap flex-row md:gap-y-3 md:py-0 gap-x-1.5 gap-y-1.5 px-3 py-[22px]`}>
            <CartOrderInfo
              info={{
                name: props.cart.name,
                phone: props.cart.phone,
                email: props.cart.email,
                payment_method: props.cart.payment_method,
                expected_time: props.cart.expected_time,
                store_table_name: props.cart.store_table_name,
                pickup_time: props.cart.pickup_time,
                number_of_orders: props.cart.number_of_orders,
                address: props.cart.address,
              }}
            />
          </View>
        </View>
      </View>

      {/* Footer */}
      {props.cart.actions ? (
        <CartActionButtons
          actions={props.cart.actions}
          onShowModalCartAction={
            props.onShowModalCartAction && onShowModalCartAction
          }
        />
      ) : null}
    </View>
  );
};

export const MemoizedCart = memo(Cart, (prevProps, nextProps) => {
  return ['id', 'updated_at', 'total'].every(key => {
    return prevProps.cart[key] === nextProps.cart[key];
  });
});
