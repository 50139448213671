import { format } from 'date-fns';
import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface CashbookGroupEntriesProps {
  date: string;
}

export const CashbookGroupEntries: React.FC<
  CashbookGroupEntriesProps
> = props => {
  return (
    <View style={tw`gap-y-5`}>
      <Text style={tw`text-[20px] leading-normal font-semibold text-gray-900`}>
        {format(props.date, 'dd MMMM')}
      </Text>
    </View>
  );
};
