import React from 'react';
import { TextInput, TextInputProps, View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';

interface InputProps extends TextInputProps {
  rightIcon?: React.ReactNode;
  error?: string | null;
}

export const Input = (props: InputProps) => {
  return (
    <>
      <TextInput
        placeholderTextColor={tw.color('gray-500')}
        {...props}
        style={[
          tw`px-3 py-4 text-lg leading-snug text-gray-800 rounded-lg border bg-white w-full`,
          props.style,
          props.error ? tw`border-error` : tw`border-gray-400`,
        ]}
      />

      {props.rightIcon ? (
        <View style={tw`absolute top-[20px] right-[15px]`}>
          {props.rightIcon}
        </View>
      ) : null}

      {props.error ? (
        <Text style={tw`pl-1 text-error`}>{props.error}</Text>
      ) : null}
    </>
  );
};
