import React from 'react';
import { useOrderQueries } from '../../../api/hooks/cash-register/orders/use-order-queries';
import { View } from 'react-native';
import { TransactionSummaryCard } from '../../../components/cash-register/transaction/transaction-summary-card';
import { tw } from '../../../utils/tailwind';
import { OrdersParams } from '../../../api/types/cash-register/order-types';
import { OrderSummaryActionButtons } from './order-summary-action-buttons';
import { useWindowSize } from '../../../hooks/use-window-size';
import { FlatListPagination } from '../../../components/flat-list-pagination';

interface OrderListProps extends OrdersParams {}

export const OrderList = (props: OrderListProps) => {
  // Hooks
  const { windowSize } = useWindowSize();
  const { fetchOrders } = useOrderQueries({
    q: props.q,
    sort_by: 'ordered_at desc',
  });

  const isXl = windowSize === 'xl';

  return (
    <FlatListPagination
      key={isXl ? 'xl' : 'sm'}
      query={fetchOrders}
      refreshing={fetchOrders.isRefetching}
      onRefresh={fetchOrders.refetch}
      collectionName="orders"
      keyExtractor={(item, _index) => item.id.toString()}
      renderItem={({ item }) => (
        <View key={item.id} style={tw`md:basis-[49.2%]`}>
          <TransactionSummaryCard
            document={item}
            actionButton={() => <OrderSummaryActionButtons {...item.actions} />}
          />
        </View>
      )}
      ItemSeparatorComponent={() => <View style={tw`h-[18px]`} />}
      numColumns={isXl ? 2 : undefined}
      columnWrapperStyle={isXl ? tw`gap-[18px]` : null}
    />
  );
};
