import { format } from 'date-fns';
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  ArrowDownRightSVG,
  ArrowUpRightSVG,
  BinSVG,
} from '../../../../assets/svg';
import { tw } from '../../../utils/tailwind';
import { CashbookEntry as CashbookEntryType } from '../../../api/types/cash-register/cashbook-entry.types';

interface CashbookEntryProps {
  entry: CashbookEntryType;
  // onRemoveEntry is optional becase there's place where we can use this component without the remove button
  onRemoveEntry?: (entry: CashbookEntryType) => void;
}

export const CashbookEntry: React.FC<CashbookEntryProps> = props => {
  const isDeposit = props.entry.entry_type === 'deposit';
  const typeClass = isDeposit ? 'entry' : 'exit';

  return (
    <View style={tw`flex-row justify-between items-start gap-x-3`}>
      <View style={tw`gap-x-3 flex-row`}>
        <View
          style={[
            tw`p-3 rounded-full rotate-90 h-12 w-12`,
            Style.arrows[typeClass],
          ]}>
          {isDeposit ? (
            <ArrowUpRightSVG
              style={tw`m-auto`}
              stroke={Style.arrows.stroke[typeClass]}
            />
          ) : (
            <ArrowDownRightSVG
              style={tw`m-auto`}
              stroke={Style.arrows.stroke[typeClass]}
            />
          )}
        </View>
        <View style={tw`gap-y-1`}>
          <View style={tw`flex-row gap-x-2 justify-start`}>
            {/* date */}
            <Text
              style={tw`self-start mt-0.5 py-1 px-2 text-base leading-none text-gray-400 rounded bg-gray-100`}>
              {format(props.entry.created_at, 'dd')}
            </Text>

            <Text style={tw`text-lg leading-normal font-medium text-gray-900`}>
              {props.entry.cashbook_entry_category.name}
            </Text>
          </View>

          {/* time */}
          <Text style={tw`text-base text-caption`}>
            {format(props.entry.created_at, 'hh:mm')}
          </Text>
        </View>
      </View>

      {/* Remove entry button */}
      {props.onRemoveEntry ? (
        <TouchableOpacity
          style={tw`p-2.5 rounded-lg bg-gray-100`}
          onPress={() => props.onRemoveEntry!(props.entry)}>
          <BinSVG
            stroke={tw.color('gray-600')}
            strokeWidth={2}
            width={24}
            height={24}
            viewBox="0 0 20 24"
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const Style = {
  arrows: {
    entry: tw`bg-green-100`,
    exit: tw`bg-red-100`,
    stroke: {
      entry: tw.color('text-success'),
      exit: tw.color('text-error'),
    },
  },
};
