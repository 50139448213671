import NetInfo from '@react-native-community/netinfo';
import { onlineManager } from '@tanstack/react-query';
import { useEffect } from 'react';

// to auto refetch on reconnect
export default function useOnlineStatusManagement() {
  useEffect(() => {
    onlineManager.setEventListener(setOnline => {
      return NetInfo.addEventListener(state => {
        // isInternetReachable defaults to null if undetermined, so we check if it’s explicitly false.
        const isConnected =
          state.isConnected && state.isInternetReachable !== false;
        setOnline(!!isConnected);
      });
    });
  }, []);
}
