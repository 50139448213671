import React, { useState } from 'react';
import { FooterBar } from '../../../components/cash-register/footer-bar/footer-bar';
import { tw } from '../../../utils/tailwind';
import { FooterBarItem } from '../../../components/cash-register/footer-bar/footer-bar-item';
import { useT } from '../../../utils/helper';
import { usePayment } from '../../../contexs/payment-context';
import { useFiscalTransactionPaymentMutations } from '../../../api/hooks/cash-register/fiscal-transaction-payments/use-fiscal-transaction-payment-mutations';
import { useAuth } from '../../../contexs/auth-context';
import { PAYMENT_METHODS } from '../../../api/types/payment-method.types';

interface InvoicePaymentButtonsProps {
  amount?: number;
  invoiceId?: number;
}

export const InvoicePaymentButtons = (props: InvoicePaymentButtonsProps) => {
  // Standard Hooks
  const t = useT();
  const payment = usePayment();
  const { currentCashier } = useAuth();
  // States
  const [requesting, setRequesting] = useState(false);
  // Queries
  const { createFiscalPaymentTransaction } =
    useFiscalTransactionPaymentMutations(props.invoiceId);
  // Other const
  const paymentEnabled = props.invoiceId && !requesting && props.amount;

  const executePayment = (paymentMethod: PAYMENT_METHODS) => {
    if (!currentCashier || !props.invoiceId || !props.amount || requesting) {
      return;
    }

    setRequesting(true);

    createFiscalPaymentTransaction
      .mutateAsync({
        invoice_id: props.invoiceId,
        cashier_id: currentCashier.id,
        amount: props.amount,
        payment_method: paymentMethod,
      })
      .finally(() => setRequesting(false));
  };

  if (payment.step !== 'PAYMENT') {
    return null;
  }

  return (
    <FooterBar style={tw`gap-0 flex-wrap`}>
      <FooterBarItem
        text={t('cash')}
        active={false}
        disabled={!paymentEnabled}
        loading={requesting}
        onPress={() => executePayment('cash')}
        buttonStyles={tw`md:basis-1/2 basis-1/1`}
        textStyles={tw`text-2xl leading-none`}
      />
      <FooterBarItem
        text={t('ec')}
        active={false}
        disabled={!paymentEnabled}
        loading={requesting}
        onPress={() => executePayment('non_cash')}
        buttonStyles={tw`md:basis-1/2 basis-1/1 md:border-l md:border-t-0 border-t border-0 border-solid border-white`}
        textStyles={tw`text-2xl leading-none`}
      />
    </FooterBar>
  );
};
