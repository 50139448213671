import React from 'react';
import { ModalBase } from '../../shared/modals/modal-base';
import { View, Text } from 'react-native';
import { tw } from '../../../utils/tailwind';
import { T0TouchableOpacity as Button } from '../../forms/overrides';
import { formatCurrency, useT } from '../../../utils/helper';
import { useNavigation } from '@react-navigation/native';
import { AppStackScreenProps } from '../../../routes';
import { StoreTable } from '../../../api/types/cash-register/store-table-types';
import { OrderSessionNavigation } from './modal-store-table-info/order-session-navigation';

interface ModalStoreTableInfoProps {
  isVisible: boolean;
  storeTable: StoreTable;
  onModalHide: () => void;
}

export const ModalStoreTableInfo = (props: ModalStoreTableInfoProps) => {
  // Hooks
  const t = useT();
  const navigation = useNavigation<AppStackScreenProps<'Root'>['navigation']>();

  navigation.addListener('blur', () => {
    props.onModalHide();
  });

  return (
    <ModalBase isVisible={props.isVisible} onModalHide={props.onModalHide}>
      <View style={tw`md:gap-y-8 gap-y-4 w-full`}>
        <View style={tw`flex-row justify-between`}>
          <View style={tw`gap-y-0.5 grow-1 shrink-1`}>
            <Text style={tw`text-[28px] leading-none font-bold text-gray-900`}>
              {props.storeTable.name}
            </Text>
            <Text style={tw`text-lg leading-snug font-semibold text-gray-500 `}>
              {props.storeTable.store_area.name}
            </Text>
          </View>
          {props.storeTable.order_session ? (
            <Text
              style={tw`grow-0 text-[28px] leading-none font-bold text-success mt-5`}>
              {formatCurrency(props.storeTable.order_session.total)}
            </Text>
          ) : null}
        </View>

        <View style={tw`flex-row flex-wrap gap-y-3`}>
          {/* Order History, Payment History */}
          <Button
            theme="lightGray"
            label={t('orderHistory')}
            extraLabelStyle="text-center"
            extraStyle="basis-[47%] grow mr-1.5"
            onPress={() => {
              props.onModalHide();
              navigation.navigate('Orders', {
                store_table_id: props.storeTable.id,
              });
            }}
          />
          <Button
            theme="lightGray"
            label={t('paymentHistory')}
            extraLabelStyle="text-center"
            extraStyle="basis-[47%] grow ml-1.5"
            onPress={() => {
              props.onModalHide();
              navigation.navigate('Invoices', {
                store_table_id: props.storeTable.id,
              });
            }}
          />
          {props.storeTable.order_session ? (
            <Button
              onPress={() =>
                navigation.navigate('OrderSessionEdit', {
                  id: props.storeTable.order_session!.id,
                })
              }
              theme="lightGray"
              label={t('modify')}
              extraStyle="basis-1/1"
              extraLabelStyle="normal-case"
            />
          ) : null}
        </View>
        {/* Pay & New Order Button */}
        <OrderSessionNavigation storeTable={props.storeTable} />
      </View>
    </ModalBase>
  );
};
