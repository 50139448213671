import React, { useEffect } from 'react';
import { ModalBase, ModalBaseProp } from '../../shared/modals/modal-base';
import { tw } from '../../../utils/tailwind';
import { View, Text, Switch } from 'react-native';
import { useT } from '../../../utils/helper';
import { Input } from '../forms/input';
import { TouchableOpacity } from '../../forms';
import {
  useCustomerForm,
  initialValues,
} from '../../../forms/use-customer-form.hook';
import { useCustomerMutations } from '../../../api/hooks/cash-register/customers/use-customer-mutations';
import {
  Customer,
  CustomerParams,
} from '../../../api/types/cash-register/customer-types';
import { CustomerSearchInput } from '../forms/customer-search-input/customer-search-input';
import { useAlert } from '../../../contexs/alert-context';

interface ModalCustomerFormProp extends Omit<ModalBaseProp, 'children'> {
  customer: Customer | null;
  isAddressRequired: boolean;
  submitButtonText?: string;
  onModalHide: () => void;
  onSubmitSuccess?: (customerId: number) => void;
}

export const ModalCustomerForm = (props: ModalCustomerFormProp) => {
  // Standard hooks
  const t = useT();
  const alert = useAlert();
  // Mutations
  const onMutationSuccess = () => {
    props.onModalHide();
  };
  const { createCustomer, updateCustomer, deleteCustomer } =
    useCustomerMutations();
  // Form
  const form = useCustomerForm(props.isAddressRequired, values => {
    const mutation = values?.id ? updateCustomer : createCustomer;

    mutation.mutateAsync(values).then(response => {
      onMutationSuccess();

      if (props.onSubmitSuccess) {
        props.onSubmitSuccess(response.id);
      }
    });
  });
  // Callbacks
  const onDeleteCustomer = (id: number) => {
    alert.setModalOptions({
      onModalHide: alert.hide,
      onConfirm: () => {
        deleteCustomer.mutateAsync(id).then(onMutationSuccess);
      },
    });
    alert.show(t('areYouSureYouWantToDelete'));
  };
  // Effects
  // Set form initial values on customer prop change
  useEffect(() => {
    // Set form values
    form.setValues((props.customer as CustomerParams) || initialValues);
  }, [props.customer]);
  // Reset form on modal hide
  useEffect(() => {
    if (!props.isVisible) {
      form.resetForm();
    }
  }, [props.isVisible]);

  return (
    <ModalBase
      title={t('customer').toString()}
      titleStyle={tw`text-center max-w-full`}
      {...props}>
      <View style={tw`w-full gap-y-3`}>
        <CustomerSearchInput
          onSelected={selectedCustomer => form.setValues(selectedCustomer)}
        />
        {/* Customer name, street, street number and city inputs */}
        <Input
          autoFocus={true}
          placeholder={t('customerAttributes.name').toString()}
          value={form.values.name}
          onChangeText={form.handleChange('name')}
          error={form.touched.name ? form.errors.name : null}
        />
        <Input
          placeholder={t('customerAttributes.phoneNumber').toString()}
          keyboardType="phone-pad"
          value={form.values.phone || ''}
          onChangeText={form.handleChange('phone')}
        />
        <View style={tw`flex-row gap-x-1.5`}>
          {/* Street and street number inputs, wrap in View to accommodate errors */}
          <View style={tw`basis-2/3`}>
            <Input
              placeholder={t('customerAttributes.street').toString()}
              value={form.values.street || ''}
              onChangeText={form.handleChange('street')}
              error={form.errors.street}
            />
          </View>

          <View style={tw`basis-1/3`}>
            <Input
              placeholder={t('customerAttributes.streetNumber').toString()}
              value={form.values.street_number || ''}
              onChangeText={form.handleChange('street_number')}
              error={form.errors.street_number}
            />
          </View>
        </View>
        <Input
          placeholder={t('customerAttributes.city').toString()}
          value={form.values.city || ''}
          onChangeText={form.handleChange('city')}
          error={form.errors.city}
        />

        {/* Permanent address checkbox */}
        <View style={tw`flex-row justify-between mt-1.5`}>
          <Text style={tw`text-lg leading-snug text-gray-800`}>
            {t('setAsPermanentAddress')}
          </Text>
          <Switch
            value={form.values.is_permanent_address}
            onValueChange={val => {
              form.setFieldValue('is_permanent_address', val);
            }}
          />
        </View>

        <View style={tw`gap-y-3 mt-8`}>
          {/* Delete button */}
          {form.values.id ? (
            <TouchableOpacity
              label={t('delete')}
              theme="red"
              onPress={() => onDeleteCustomer(form.values.id!)} // ! needed non-null assertion operator since id can't be undefined (we have have checked it few lines above)
            />
          ) : null}

          {/* Save button */}
          <TouchableOpacity
            label={
              props.submitButtonText || t(form.values.id ? 'save' : 'create')
            }
            onPress={() => form.handleSubmit()}
          />
        </View>
      </View>
    </ModalBase>
  );
};
