import request from '../../../request';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InvoicesQrResponse } from '../../../types/cash-register/invoice.types';

export const useInvoiceQrQuery = (id: number) => {
  const queryClient = useQueryClient();

  const fetchInvoiceQr = useQuery(
    ['invoices', id, 'qr'],
    async () =>
      request({
        method: 'get',
        url: `/store/invoices/${id}/qr.json`,
      }) as Promise<InvoicesQrResponse>,
    {
      onSuccess: () => queryClient.invalidateQueries(['storeAreas']),
    },
  );

  return { fetchInvoiceQr };
};
