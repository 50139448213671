import { useEffect, useState } from 'react';
import { useTenantsMutations } from '../../api/hooks/tenants/use-tenants-mutations';
import { useAuth } from '../../contexs/auth-context';
import env from '../../env';
import useActionCable from './use-action-cable';
import useChannel from './use-channel';
import { useTenantsQueries } from '../../api/hooks/tenants/use-tenants-queries';
import NetInfo from '@react-native-community/netinfo';

export default function useOpeningStateChannel() {
  const { authData } = useAuth();
  const { actionCable } = useActionCable(
    `${env.WEBSOCKET_URL}?token=${authData?.token}`,
  );
  const { subscribe, unsubscribe } = useChannel(actionCable);
  const { setDisconnected } = useTenantsMutations();
  const { fetchTenantsDashboard } = useTenantsQueries();

  const [isNetworkConnected, setNetworkConnected] = useState(true); // Tracks network connection state

  const refetchTenantsDashboard = () => {
    if (fetchTenantsDashboard.isStale) {
      fetchTenantsDashboard.refetch();
    }
  };

  useEffect(() => {
    // Listen for network connection changes using NetInfo
    const unsubscribeNetInfo = NetInfo.addEventListener(state => {
      // isInternetReachable defaults to null if undetermined, so we check if it’s explicitly false.
      const isConnected =
        state.isConnected && state.isInternetReachable !== false;
      setNetworkConnected(!!isConnected); // Update the network connection state
    });

    return () => {
      unsubscribeNetInfo(); // Cleanup the NetInfo listener
    };
  }, []);

  useEffect(() => {
    subscribe(
      { channel: 'OpeningStateChannel' },
      {
        received: () => {
          refetchTenantsDashboard();
        },
        connected: () => {
          refetchTenantsDashboard();
        },
        disconnected: () => {
          setDisconnected();
          // Action Cable will automatically try to reconnect
        },
      },
    );

    return () => {
      unsubscribe();
    };
  }, [isNetworkConnected]); // Re-run effect when network connection state changes
}
